<template>
  <div>
    <div class="d-sm-block d-mb-block d-lg-none">
      <!-- position: relative; z-index: 100" -->
      <div class="header-content-mobile">
        <div class="header-logo-mobile">
          <router-link to="/">
            <img
              class="header-logo-img"
              src="@/assets/img/logo-mobile-2.svg"
              alt="Header Logo"
            />
          </router-link>
        </div>
        <div class="switch-language-mobile">
          <p
            class="switch_ru-mobile"
            :class="{ active: isLocaleActive('ru') }"
            @click="changeLocale('ru')"
          >
            {{ $t("rus") }}
          </p>
          <p
            class="switch_eng-mobile"
            :class="{ active: isLocaleActive('en') }"
            @click="changeLocale('en')"
          >
            {{ $t("eng") }}
          </p>

          <div @click="toggleMenu" class="burger-menu-container">
            <img src="@/assets/img/burger-2-header.svg" alt="" />
          </div>
        </div>
        <v-navigation-drawer
          app
          right
          temporary
          v-model="menuVisible"
          :width="drawerWidth"
          :height="drawerHeight"
        >
          <!-- Содержимое вашего бургер-меню -->
          <v-list>
            <div @click="burgerClose" class="burger-close">
              <img src="@/assets/img/burger-close.svg" alt="" />
            </div>
            <div class="gap-container">
              <div class="link-container">
                <router-link class="no-underline-mobile" to="/">
                  <p class="nav-list--mobile pt-1">{{ $t("mainpage") }}</p>
                </router-link>
                <div class="horizontal-line"></div>
                <div
                  class="no-underline-mobile btn-arrow"
                  @click="toggleSubcategories"
                >
                  <p class="nav-list--mobile">{{ $t("production") }}</p>
                  <img
                    v-if="!this.showSubcategories"
                    class="arrow-close-open"
                    src="@/assets/img/open-down.svg"
                    alt=""
                  />

                  <img
                    v-if="showSubcategories"
                    class="arrow-close-open"
                    src="@/assets/img/open-up.svg"
                    alt=""
                  />
                </div>
                <div class="horizontal-line"></div>
                <div class="sub-category-container" v-if="showSubcategories">
                    <div v-for="category in filteredCategories.filter(cat => cat.stateCode === 'Active')" :key="category.id" @click="chooseCategory(category)">
                    <div class="sub-category-1">
                    <router-link
                      class="no-underline-mobile sub-category"
                      :to="{ path: `/categories/${category.id}` }"
                      >{{category.name}}</router-link
                    >
                    </div>
                    <div class="horizontal-line-sub"></div>
                  </div>
                  <!-- <div class="sub-category-1">
                    <router-link
                      class="no-underline-mobile sub-category"
                      to="/categories"
                      >{{ $t("product-submenu-1") }}</router-link
                    >
                  </div>
                  <div class="horizontal-line-sub"></div>
                  <div class="sub-category-1">
                    <router-link
                      class="no-underline-mobile sub-category"
                      to="/categories"
                      >{{ $t("product-submenu-2") }}</router-link
                    >
                  </div>
                  <div class="horizontal-line-sub"></div>
                  <div class="sub-category-1">
                    <router-link
                      class="no-underline-mobile sub-category"
                      to="/categories"
                      >{{ $t("product-submenu-3") }}</router-link
                    >
                  </div>
                  <div class="horizontal-line-sub"></div>
                  <div class="sub-category-1">
                    <router-link
                      class="no-underline-mobile sub-category"
                      to="/categories"
                      >{{ $t("product-submenu-4") }}</router-link
                    >
                  </div>
                  <div class="horizontal-line-sub"></div>
                  <div class="sub-category-1">
                    <router-link
                      class="no-underline-mobile sub-category"
                      to="/categories"
                      >{{ $t("product-submenu-5") }}</router-link
                    >
                  </div>
                  <div class="horizontal-line-sub"></div>
                  <div class="sub-category-1">
                    <router-link
                      class="no-underline-mobile sub-category"
                      to="/categories"
                      >{{ $t("product-submenu-6") }}</router-link
                    >
                  </div>
                  <div class="horizontal-line-sub"></div>
                  <div class="sub-category-1">
                    <router-link
                      class="no-underline-mobile sub-category"
                      to="/categories"
                      >{{ $t("product-submenu-7") }}</router-link
                    >
                  </div>
                  <div class="horizontal-line-sub"></div> -->
                </div>
                <!-- <div class="horizontal-line"></div> -->
                <router-link class="no-underline-mobile" to="/distributor">
                  <p class="nav-list--mobile">{{ $t("distributors") }}</p>
                </router-link>
                <div class="horizontal-line"></div>
                <router-link class="no-underline-mobile" to="/contacts">
                  <p class="nav-list--mobile">{{ $t("contactUs") }}</p>
                </router-link>
                <div class="horizontal-line"></div>
              </div>
            </div>
          </v-list>
          <div class="burger-bot-img-container">
            <img src="@/assets/img/burger-bot-img.svg" alt="" />
          </div>
        </v-navigation-drawer>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <div class="header" style="">
        <!-- position: relative; z-index: 100" -->
        <div class="header-content">
          <div class="header-logo">
            <router-link to="/">
              <img src="@/assets/img/logo2-header.svg" alt="Header Logo" />
            </router-link>
          </div>
          <div class="header-nav-list">
            <router-link to="/" style="text-decoration: none">
              <p class="nav-list-1">{{ $t("mainpage") }}</p>
            </router-link>
            <p class="nav-list-2" @mouseenter="showSubMenu">
              {{ $t("production") }}
            </p>
            <ul class="sub-menu" ref="subMenu" @mouseleave="hideSubMenu">
              <li v-for="category in filteredCategories.filter(cat => cat.stateCode === 'Active')" :key="category.id"  @click="chooseCategory(category)">
                <router-link
                  :to="{ path: `/categories/${category.id}` }"
                  class="redirect_category"
                  style="text-decoration: none"
                >
                  {{ category.name }}
                </router-link>
              </li>
              <!-- <li>
                <router-link
                  to="/categories"
                  class="redirect_category"
                  style="text-decoration: none"
                >
                  {{ $t("product-submenu-2") }}
                </router-link>
              </li>
              <li>
                <router-link
                  to="/categories"
                  class="redirect_category"
                  style="text-decoration: none"
                >
                  {{ $t("product-submenu-3") }}
                </router-link>
              </li>
              <li>
                <router-link
                  to="/categories"
                  class="redirect_category"
                  style="text-decoration: none"
                >
                  {{ $t("product-submenu-4") }}
                </router-link>
              </li>
              <li>
                <router-link
                  to="/categories"
                  class="redirect_category"
                  style="text-decoration: none"
                >
                  {{ $t("product-submenu-5") }}
                </router-link>
              </li>
              <li>
                <router-link
                  to="/categories"
                  class="redirect_category"
                  style="text-decoration: none"
                >
                  {{ $t("product-submenu-6") }}
                </router-link>
              </li>
              <li>
                <router-link
                  to="/categories"
                  class="redirect_category"
                  style="text-decoration: none"
                >
                  {{ $t("product-submenu-7") }}
                </router-link>
              </li> -->
            </ul>
            <router-link to="/distributor" style="text-decoration: none">
              <p class="nav-list-3">{{ $t("distributors") }}</p>
            </router-link>
            <router-link to="/contacts" style="text-decoration: none">
              <p class="nav-list-4">{{ $t("contactUs") }}</p>
            </router-link>
          </div>
          <div class="switch-language">
            <p
              class="switch_ru"
              :class="{ active: isLocaleActive('ru') }"
              @click="changeLocale('ru')"
            >
              {{ $t("rus") }}
            </p>
            <p
              class="switch_eng"
              :class="{ active: isLocaleActive('en') }"
              @click="changeLocale('en')"
            >
              {{ $t("eng") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapMutations } from "vuex";
export default {
  name: "Header2",
  data() {
    return {
      menuVisible: false,
      drawerWidth: "85%",
      drawerHeight: "100%",
      showSubcategories: false,
      categories:[],
    };
  },

  created() {
    // Попытайтесь получить локаль из локального хранилища
    const savedLocale = localStorage.getItem("app_locale");

    // Если локаль найдена в локальном хранилище, установите ее
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    this.getCategoryList();
  },
  computed: {
    filteredCategories() {
      return this.categories && this.categories.items
    ? this.categories.items
    : [];
    },
    // Вычисляемое свойство, которое определяет, активна ли локаль 'ru'
    isActiveRu() {
      return this.$i18n.locale === "ru";
    },
    // Вычисляемое свойство, которое определяет, активна ли локаль 'en'
    isActiveEn() {
      return this.$i18n.locale === "en";
    },
    currentLocale() {
      return  this.$i18n.locale; // Получаем текущую локаль из хранилища Vuex
    }
  },
  methods: {
    ...mapMutations(["setCurrentCategory"]),
    toggleSubcategories() {
      if (this.menuVisible) {
        this.showSubcategories = !this.showSubcategories; // Инвертируем видимость подкатегорий при клике
      }
    },
    burgerClose() {
      this.menuVisible = !this.menuVisible;
      this.showSubcategories = false;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible; // Изменение состояния видимости бургер-меню
      this.showSubcategories = false;
    },

    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("app_locale", locale);
    },
    isLocaleActive(locale) {
      return this.$i18n.locale === locale;
    },
    showSubMenu() {
      // Ваш код для отображения подменю
      // Например:
      this.$refs.subMenu.style.display = "block";
      document.addEventListener("click", this.handleClickOutside);
    },
    hideSubMenu() {
      // Ваш код для скрытия подменю
      // Например:
      this.$refs.subMenu.style.display = "none";
    },
    handleClickOutside(event) {
      // Проверьте, был ли клик сделан вне меню
      if (
        this.$refs.subMenu &&
        !this.$refs.subMenu.contains(event.target) &&
        !event.target.classList.contains("nav-list-2")
      ) {
        this.hideSubMenu(); // Закройте меню, если клик был вне его
        // Удалите обработчик события клика после закрытия меню
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    getCategoryList() {
      this.$http
          .put("/api/Category/PagedList", {
            pageNumber: this.currentPage,
            pageSize: 100,
            sorting: {
            property: "SortOrder",
            isDescending: false
          },
            headers: {
                      'Content-Type': 'application/json',
                    },
          })
          .then((response) => {
            this.categories = response.data;
          //   this.totalPages = response.data.totalPages; // Общее количество страниц
          // this.totalItems = response.data.totalCount;
          })
          .catch((error) => (this.error = error));
    },
    chooseCategory(item) {
    // this.router.push('/categories/')
    this.$store.commit("setCurrentCategory", item);
    console.log(item);
    }
  },
  watch: {
    currentLocale: {
      handler() {
        // Выполняйте запросы к API при изменении локали
        this.getCategoryList(); // Передаем новую локаль в функцию для обновления данных
        // this.getCategory();    // Передаем новую локаль в другую функцию для обновления данных
      },
      // immediate: true // Вызывать обработчик сразу после создания компонента
    }
  }
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.sub-category-1 {
  margin-top: 10px;
}
.sub-category-container {
  margin-left: 15px;
}
.sub-category {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.horizontal-line-sub {
  width: 100%; /* Ширина полоски будет равной ширине родительского контейнера */
  height: 1px; /* Высота полоски (можно изменить на ваше усмотрение) */
  margin-top: 10px;
  background: var(--05, #dae3ea);
}
.arrow-close-open {
  padding-top: 18px;
  margin-right: 22px;
}
.btn-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switch_eng-mobile:hover {
  color: var(--06, #bcb648);
  font-weight: 600;
}
.switch_ru-mobile:hover {
  color: var(--06, #bcb648);
  font-weight: 600;
}

.switch_ru-mobile:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0px;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}
.switch_eng-mobile:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0px;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}

.switch_ru-mobile {
  color: var(--Gray-2, #4f4f4f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.408px;
  text-transform: lowercase;
}
.switch_eng-mobile {
  color: var(--Gray-2, #4f4f4f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.408px;
  text-transform: lowercase;
}
::v-deep .v-navigation-drawer__content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.gap-container {
  display: flex;
  justify-content: space-between; /* Равномерное распределение пространства между дочерними элементами */
  flex-direction: column;
}
.burger-bot-img-container {
  text-align: center;
  /* margin-top: 45vh; */
}
.horizontal-line {
  width: 100%; /* Ширина полоски будет равной ширине родительского контейнера */
  height: 1px; /* Высота полоски (можно изменить на ваше усмотрение) */
  margin-top: 18px;
  background: var(
    --05,
    #dae3ea
  ); /* Цвет фона с использованием переменной и значения по умолчанию */
}
.link-container {
  margin-left: 26px;
}
.burger-close {
  text-align: end;
  margin-top: 44px;
  margin-right: 23px;
}
.no-underline-mobile {
  text-decoration: none;
}
.nav-list--mobile {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 18px;
}
.switch_eng-mobile {
  margin-left: 23px;
}
.burger-menu-container {
  margin-left: 45px;
}
.switch-language-mobile {
  display: flex;
}
.header-content-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 31px 16px 0px;
}

.active {
  color: #bcb648 !important;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.408px;
  border-bottom: 2px solid #bcb648;
}
.nav-list-4:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}
.nav-list-3:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0px;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}
.nav-list-2:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0px;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}
.nav-list-1:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}
.nav-list-4:hover {
  color: var(--06, #bcb648);
}
.nav-list-3:hover {
  color: var(--06, #bcb648);
}
.nav-list-2:hover {
  color: var(--06, #bcb648);
}
.nav-list-1:hover {
  color: var(--06, #bcb648);
}
.nav-list-4:hover:after,
.nav-list-4:focus:after {
  width: 100%;
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-top: 9px;
}
.nav-list-3:hover:after,
.nav-list-3:focus:after {
  width: 100%;
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-top: 9px;
}
.nav-list-2:hover:after,
.nav-list-2:focus:after {
  width: 100%;
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-top: 9px;
}
.nav-list-1:hover:after,
.nav-list-1:focus:after {
  width: 100%;
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-top: 9px;
}
.nav-list-4 {
  margin-bottom: 0px;
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 109px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  min-width: 103px;
}
.nav-list-3 {
  margin-bottom: 0px;
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 105px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  min-width: 167px;
}
.nav-list-2 {
  margin-bottom: 0px;
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 109px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  min-width: 108px;
}
.nav-list-1 {
  margin-bottom: 0px;
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  min-width: 74px;
}
.header-nav-list {
  display: flex;
  flex-direction: row;
}
.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 4.62962962963vh; */
  width: 100%;
  padding: 20px 12px;
  margin-right: auto;
  margin-left: auto;
}
.header-logo {
  width: 365px;
}
.switch-language {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 166px;
}
.switch_ru {
  color: var(--Gray-2, #4f4f4f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.408px;
  cursor: pointer;
  margin-right: 23px;
  /* text-transform: lowercase; */
}
.switch_eng {
  color: var(--Gray-2, #4f4f4f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.408px;
  text-transform: lowercase;
  cursor: pointer;
  position: relative;
}
.switch_eng:hover {
  color: var(--06, #bcb648);
  font-weight: 600;
}
.switch_ru:hover {
  color: var(--06, #bcb648);
  font-weight: 600;
}

.switch_ru:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0px;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}
.switch_eng:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0px;
  height: 2px;
  background-color: #bcb648;
  content: "";
  transition: width 0.5s ease-out;
  color: #000000;
  margin-top: 9px;
}
.sub-menu {
  background-color: white;
  border-radius: 10px; /* Закругленные углы */
  padding: 15px 0 15px 0;
  position: absolute;
  width: 343px;
  top: 11.156vh;
  left: 43%;
  z-index: 100;
  display: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); /* Тень для подменю */
}

/* .nav-list-2:hover + .sub-menu,
.sub-menu:hover {
  display: block;
} */

.sub-menu li {
  list-style: none;
  margin-bottom: 10px;
  margin-left: 20px;
  border-bottom: 1px solid var(--05, #dae3ea);
}
.redirect_category {
  text-decoration: none !important;
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  padding-bottom: 10px; /* Добавляем отступ снизу для разделителей */
  transition: color 0.3s ease-in-out;
}

.sub-menu li:last-child {
  border-bottom: none;
  margin-bottom: 0px; /* Убираем разделитель для последнего элемента */
}

.sub-menu a:hover {
  color: var(--06, #bcb648); /* Цвет при наведении */
}
@media screen and (max-width: 281px) {
  /* Задаем высоту картинки на фоне в vh (например, 50vh) */
  .burger-menu-container {
    margin-left: 20px;
  }
  .header-logo-img {
    width: 98px;
  }
}

@media (max-width: 1263px) {
  .header-content-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 31px 32px 0px;
  }
}
@media screen and (max-width: 365px) {
  .header-content-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 31px 16px 0px;
  }
}

@media (max-width: 653px) {
  .header-content-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 31px 16px 0px;
  }
}
</style>
  